<template>
  <div>
    <b-row v-if="siparisList.length > 0">
      <b-col v-for="item in siparisList" :key="item.id" xl="4" lg="6" md="6" sm="12" cols="12">
        <b-card-actions
            ref="first-card"
            class="list-card h-100"
            @refresh="refreshStop('first-card')"
        >
          <div class="siparis-card">
            <b-row class="mt-1">
              <b-col cols="12">
                <div class="first-part">
                  <b-avatar variant="warning" class="siparis-avatar pull-up" size="45px">
                    <font-awesome-icon icon="utensils" size="2xl"/>
                  </b-avatar>
                  <div class="d-inline-block">
                    <div><strong class="ml-50"> {{ item.userName }} </strong> / <small> # {{ item.adisyonID }} </small>
                    </div>
                    <div class="pl-50 text-muted">{{ item.masaAdi }}</div>
                  </div>
                </div>
                <div class="second-part">
                  <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="relief-success"
                      @click="hazirSiparis(1,item.adisyonID)"
                  >
                    Tümü Hazır
                  </b-button>
                </div>
              </b-col>
            </b-row>
            <h5 v-if="item.adisyonNotu" class="mt-1 font-weight-bolder d-inline-block">Not : </h5>
            <h5 v-if="item.adisyonNotu" class="d-inline-block"> {{ item.adisyonNotu }} </h5>
            <div class="siparis-divider"/>
            <b-list-group flush>
              <b-list-group-item v-for="siparis in item.siparisBilgileri" :key="siparis.detayID"
                                 class="d-flex justify-content-between align-items-center"
              >
                <div class="item-first-part">
                  <b-badge :key="gecenSureCount" :variant="!siparis.isExpired ? 'light-primary' :'light-danger'"
                           class="siparis-sure"
                  >
                    <font-awesome-icon icon="clock" class="mr-50"/>
                    <p class="d-inline-block m-0">{{ calculateElapsedTime(siparis.sure) }}</p>
                  </b-badge>
                  <div class="vertical-divider"/>
                  <div class="d-grid ml-1">
                    <small class="font-weight-bolder text-muted">
                      <font-awesome-icon v-if="siparis.userName" icon="user"/>
                      {{ siparis.userName }}
                    </small>
                    <p class="font-weight-bolder m-0">
                      {{ `${siparis.adet} x ${siparis.stokAdi}` }}
                    </p>
                    <p class="font-weight-bolder m-0 mutfak-birim-notu">
                      {{ siparis.birimNotu }}
                    </p>
                  </div>
                </div>
                <div class="item-second-part">
                  <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            :variant="siparis.siparisDurum === '0' ? 'outline-success':'outline-primary'"
                            :disabled="siparis.siparisDurum === '1'"
                            @click="hazirSiparis(2,siparis.detayID)"
                  >
                    {{ siparis.siparisDurum === '0' ? 'Hazır' : 'Hazırlandı' }}
                  </b-button>
                </div>
              </b-list-group-item>
            </b-list-group>
          </div>
        </b-card-actions>
      </b-col>
    </b-row>
    <b-row v-else>
      <b-col cols="12" class="p-2">
        <h2>Sipariş Listesi (Bekleyen Sipariş Bulunmuyor)</h2>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BAvatar,
  BTabs,
  BTab,
  BButton,
  BRow,
  BCol,
  BModal,
  BListGroup,
  BListGroupItem,
  BFormGroup,
  BFormInput,
  BCardText,
  BFormCheckbox,
  BFormRadio,
  BSpinner,
  VBTooltip,
  BBadge,
  BContainer,
} from 'bootstrap-vue'
import store from '@/store'
import Ripple from 'vue-ripple-directive'
import axios from 'axios'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import moment from 'moment'

export default {
  components: {
    BAvatar,
    BCardActions,
    BContainer,
    BTabs,
    BTab,
    BButton,
    BRow,
    BCol,
    BModal,
    BListGroup,
    BListGroupItem,
    BFormGroup,
    BFormInput,
    BCardText,
    BFormCheckbox,
    BFormRadio,
    BSpinner,
    VBTooltip,
    BBadge,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      gecenSureCount: 0,
    }
  },
  computed: {
    title: () => 'Gelen Siparişler',
    siparisList: () => store.getters.GET_SIPARIS_LIST || [],
  },
  beforeDestroy() {
    clearInterval(this.timer)
    store.commit({
      type: 'SET_SIPARIS_LIST',
      action: 0,
    })
  },
  created() {
    this.getSiparisData()
  },
  mounted() {
    setInterval(() => {
      this.getSiparisData()
    }, 10000)
    this.timer = setInterval(() => {
      this.updateElapsedTimes()
    }, 1000)
  },
  methods: {
    hazirSiparis(tur, id) {
      const fd = this.postSchema()
      if (tur === 1) {
        fd.append('methodName', 'adisyonSiparisStatus')
        fd.append('adisyonID', id)
        axios.post('', fd)
            .then(response => {
              if (response.result.status === 200) {
                this.getSiparisData()
              }
            })
      } else {
        fd.append('methodName', 'siparisStatus')
        fd.append('detayID', id)
        axios.post('', fd)
            .then(response => {
              if (response.result.status === 200) {
                this.getSiparisData()
              }
            })
      }
    },
    updateElapsedTimes() {
      const currentTime = moment()
      Object.values(this.siparisList)
          .forEach(order => {
            if (order.siparisBilgileri) {
              order.siparisBilgileri.forEach(siparis => siparis.isExpired = currentTime.diff(moment(siparis.sure, 'YYYY-MM-DD HH:mm:ss'), 'minutes') >= 20)
            }
          })
      this.gecenSureCount++
    },
    calculateElapsedTime(insertTime) {
      const startTime = moment(insertTime, 'YYYY-MM-DD HH:mm:ss')
      const now = moment()
      const duration = moment.duration(now.diff(startTime))
      return moment.utc(duration.asMilliseconds())
          .format('HH:mm:ss')
    },
    getSiparisData() {
      const fd = this.postSchema()
      fd.append('methodName', 'siparisListesi')
      axios.post('', fd)
          .then(response => {
            store.commit({
              type: 'SET_SIPARIS_LIST',
              action: 1,
              list: response.siparis,
            })
          })
    },
    postSchema() {
      const fd = new FormData()
      fd.append('crm_token', store.state.userToken || localStorage.getItem('restoranUserToken'))
      fd.append('serviceName', 'restoran')
      return fd
    },
    refreshStop(cardName) {
      setTimeout(() => {
        this.$refs[cardName || 'first-card'].showLoading = false
      }, 1000)
    },
  },
}
</script>

<style scoped>
.siparis-card {
  height: 100% !important;
}

.siparis-divider {
  width: 100%;
  margin-top: 10px;
  border: 1px solid #FAF1E4;
}

.siparis-avatar {
  background-color: #FF9B50;
}

.first-part {
  place-items: center;
  display: inline-flex;
}

.second-part {
  display: inline-block;
  float: right;
}

.siparis-sure {
  place-items: center;
  display: flex;
}

.vertical-divider {
  border-left: 1px solid #ADE4DB;
  height: 30px;
  margin-left: 10px;
}

.item-first-part {
  display: flex;
}

@media (max-width: 1160px) and (min-width: 992px) {
  .siparis-avatar {
    display: none;
  }
}

@media (max-width: 401px) {
  .siparis-avatar {
    display: none;
  }
}

.expired {
  background-color: red;
}

.mutfak-birim-notu {
  color: #d94646;
}

.pull-up {
  transition: 400ms;
}

</style>
